<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Consolidado Archivos</v-card-title>

      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header> Opciones de consulta</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="12">
                      <template v-for="x, index in subConsulta.campos_busqueda">
                        </hr>
                      </template>
                      
                      <table class="tabla">
                        <thead class="v-data-table-header">
                          <tr>
                            <th class="text-left">
                              Filtro
                            </th>
                            <th class="text-left">
                              Valor
                            </th>
                            <th class="text-center">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in subConsulta.campos_busqueda"
                            :key="item.name"
                          > 
                            <td>
                              <div
                                v-if="loadingCampos"
                                class="spinner-border"
                                role="status"
                              ></div>
                              <v-select
                                  v-if="!loadingCampos"
                                  v-model="item.filtro"
                                  :items="campos"
                                  :item-text="'text'"
                                  :item-value="'value'"
                                  attach
                                  label="Criterio de búsqueda"
                                  :rules="required"
                                ></v-select>
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.valor"
                                label="Valor"
                                single-line
                                counter
                                hide-details="auto"
                                :rules="required"
                              ></v-text-field>
                            </td>
                            <td class="text-center">
                              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      
                      </table>
                      
                      <v-row>
                        <v-col cols="12" md="12" class="text-right">
                          <CButton
                            v-if="true"
                            color="primary"
                            class="px-4"
                            @click="agregarFiltro()"
                            >+ Filtro</CButton
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12" md="12">
                      <div
                        v-if="loadingCampos"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingCampos"
                        v-model="subConsulta.campos_salida"
                        :items="opcionesCamposSalida"
                        :item-text="'nombre_alias'"
                        :item-value="'nombre'"
                        attach
                        chips
                        label="Campos de salida"
                        :rules="required"
                        multiple
                        return-object
                      >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                        >
                          <v-list-item-action>
                            <v-icon :color="subConsulta.campos_salida.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Seleccionar todos los campos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <CButton
                        color="primary"
                        class="px-4"
                        :disabled="!(valid && subConsulta.campos_salida.length)"
                        @click="consultar()"
                        ><CIcon name="cil-magnifying-glass" /> Obtener consulta</CButton
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        
        <v-container v-if="items.length">      
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="search"
                label="Buscar en los resultados"
                class="mx-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="text-right" v-show="false">
              <v-spacer></v-spacer>

              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="descargar()"
                :disabled="loadingDescargar"
              >
                Descargar
                <v-icon
                  right
                  dark
                >
                  mdi-file-download
                </v-icon>
                &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
              </v-btn>

            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          item-key="name"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, 200, 500, -1]
          }"
          :items-per-page="50"
          :loading='loading'
          loading-text="Cargando..."
        >

          <template v-slot:item.layout_id="{ item }">
            {{item.layout.id+". "+item.layout.nombre}}
          </template>
          
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editar(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon v-if="permisoEjecucion"
              small
              class="mr-2"
              @click="ejecutar(item)"
            >
              mdi-gavel
            </v-icon>
          </template>
        </v-data-table>
        <small>Se visualizarán máximo 5.000 registros.</small>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>
    <br><br>

      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        width="98%"
        height="90"
      >
        
      </v-dialog>

  </div>
</template>

<script>
import consultaAutogestorService from "@/services/definicionConsulta.service";
import ConsolidadoArchivosService from "@/services/consolidadoArchivos.service";
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      consolidacion: {
        nombre: undefined,
        descripcion: undefined,
        layout_id: undefined,
        relaciones: [],
        campos: [],
        agrupar: 1,
        activo: 1,
      },
      subConsulta: {
        campos_busqueda: [
          /* { filtro: "Nombre", valor: "" },
          { filtro: "Layout", valor: "" }, */
        ], 
        campos_salida: [
        { nombre_alias: "Código", value: "id" },
        { nombre_alias: "Nombre", value: "nombre" },
        { nombre_alias: "Layout", value: "layout_id" },
        ],
        idTemp: '',
        currentdate: null,
        consulta: null
      },
      panel: [1,0],
      valid: false,
      permisoEjecucion: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      camposBusqueda: [],
      //campos: [],
      campos: [
        { text: "Nombre", value: "nombre" },
        { text: "Layout", value: "layout_id" },
      ],
      opcionesCamposSalida: [
        { nombre_alias: "Código", value: "id" },
        { nombre_alias: "Nombre", value: "nombre" },
        { nombre_alias: "Layout", value: "layout" },
      ],
      headers: [],
      items: [],
      search: null,
      headersFiltros: [
        { text: "Filtro", value: "filtro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      loading: false,
      loadingDescargar: false,
      loadingLabelsPanoramicos: false,
      loadingCampos: false,
      message: "",
      dialog: false,
      tab: null,
      itemsTabs: [
        '.', '..', '...', '....',
      ],
      itemPanoramico: undefined
    };
  },
  components: {

  },
  watch: {
    panel(val){
      console.log(this.panel)
    }
  },
  computed: {
    ...mapState(["permissions"]),
    likesAll () {
        return this.subConsulta.campos_salida.length === this.opcionesCamposSalida.length
    },
    likesSome () {
      return this.subConsulta.campos_salida.length > 0 && !this.likesAll
    },
    icon () {
        if (this.likesAll) return 'mdi-close-box'
        if (this.likesSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  mounted() {
    if (!this.permissions.includes("Consulta Consolidacion Archivos"))
      this.$router.push("/dashboard");

    if (this.permissions.includes("Ejecutar Proceso Consolidacion"))
      this.permisoEjecucion=true

    console.log("permisoEjecución")
    console.log(this.permisoEjecucion);
  },
  methods: {
    getCampoById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre:''
    },
    getAliasById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre_alias:''
    },
    getAliasByNombre(nombre){
      let campo = this.campos.find(e => e.nombre == nombre)
      return campo?campo.nombre_alias:''
    },
    getValorCampoByNombre(nombre, item){
      return item?item[this.depurar_cadena(nombre)]:''
    },
    consultar() {
      this.items = []
      this.loading = true;
      this.headers = []
      this.subConsulta.currentdate = new Date()
      console.log("-------------- subconsulta -----------")
      console.log(this.subConsulta)
      let cadenaBusqueda = "?";
      
      this.subConsulta.campos_busqueda.forEach(function(item, index, object){
        cadenaBusqueda+=item.filtro+"="+item.valor
      });
      console.log(cadenaBusqueda)
      ConsolidadoArchivosService.getItems(cadenaBusqueda).then(
        (response) => {
          for(let campo = 0; campo < this.subConsulta.campos_salida.length; campo++) {
            this.headers.push({
              text: this.subConsulta.campos_salida[campo].nombre_alias,
              value: this.depurar_cadena(this.subConsulta.campos_salida[campo].value),
            });
          }
          this.headers.push({
            text: 'Editar',
            align: 'center',
            value: 'actions',
          });

          console.log("Resultado consulta:")
          console.log(response.data)
          this.items = response.data
          this.loading = false
        },
          (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
            console.log("Está tardando más de lo usual.  El sistema seguirá esperando el resultado.")
            //this.consultarResultado()
          }
      )
    },
    agregarFiltro() {
      this.subConsulta.campos_busqueda.push({
        filtro: "",
        valor: "",
      });
    },
    deleteItem(item) {
      let i = this.subConsulta.campos_busqueda.indexOf(item);
      this.subConsulta.campos_busqueda.splice(i, 1);
    },
    verPanoramico(item){
      this.dialog = true
      this.itemPanoramico = item
    },
    cerrarPanoramico(){
      this.dialog = false
    },
    descargar() {
      this.loadingDescargar = true
      consultaAutogestorService.getArchivo(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta ${this.consulta.nombre}.csv`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    hashCode (cadena) {
      var hash = 0,
        i, chr;
      if (cadena.length === 0) return hash;
      for (i = 0; i < cadena.length; i++) {
        chr = cadena.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    depurar_cadena (text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      text = text.replace(/ /g,"_");
      text = text.replace(/"""KEY"""/g,"_key");
      text=text.replace(/[^a-z0-9\_\-]/gi,"")
      if (text == "KEY")
        text = "_key"
      text = text.toLowerCase();
      console.log("depuracion cadena:"+text);
      return text
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.subConsulta.campos_salida = []
        } else {
          this.subConsulta.campos_salida = this.opcionesCamposSalida.slice()
        }
      })
    },
    editar(item) {
      localStorage.setItem("datos_consolidado_archivos", JSON.stringify(item));
      this.$router.push({ path: `consolidado-archivos/editarconsolidadoarchivo` });
    },
    ejecutar(item) {
      localStorage.setItem("datos_consolidado_archivos", JSON.stringify(item));
      this.$router.push({ path: `consolidado-archivos/ejecutarconsolidadoarchivo` });
    },
    goBack() {
      this.$router.push({ path: "/dashboard" });
    },
    crear() {
      localStorage.removeItem("datos_consolidado_archivos");
      this.$router.push({ path: `consolidado-archivos/editarconsolidadoarchivo` });
    },
  },
};
</script>

<style>

.v-dialog__content {
    z-index: 1030 !important;
}
.panoramico .v-input__control {
  height: 30px;
}

.tabla{
  width: 100%;
  border-width: 0.5px;
}
.tabla th{
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.tabla, .tabla tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e1e1e1;
}
.tabla td {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
